const baseUrl = 'business.gov.nl';
const apiUrl = `https://api.${baseUrl}/`;

/** @type {import('moduleAlias/environmentConfig').EnvironmentConfig} */
const environmentConfig = {
	env: 'productie', // used to determine if production/non-production GTM should be loaded
	recaptchaKey: '6Lej9sQlAAAAAKl_OihnJquskEMrE7BHAOyxOG-J',
	localWebApiUrl: 'http://localhost:8000/',
	webApiUrl: apiUrl,
	webApiContentService: 'content/v1',
	webApiImagesService: 'images/v1',
	webApiFormService: 'forms/v1',
	webApiBrowseService: 'browse/v1',
	port: 80,
	cacheEnabled: true,
	searchUrl: `https://search.${baseUrl}/api/v3/search`,
	searchScOrganisationsUrl:
		'https://zoeken.ondernemersplein.kvk.nl/api/v2/search/scorganisaties',
	imageCDN: 'https://production.kvk-dop.bloomreach.cloud',
	hasCmsPreview: false,
	switchEnvURL: 'https://staging.business.gov.nl',
	switchEnvLabel: 'Naar preview',
	previewRefreshURL: '', //no auto refresh on PRD

	originUrl: `https://${baseUrl}`,
	flagshipEnabled: false, //true,
	flagshipApiUrl: 'https://decision-api.flagship.io/v1',
	flagshipEnviromentId: 'bnft3ccdg2jg2rif6urg', // 'bnft3ccdg2jg2rif6urg' in PROD,
	flagshipMode: 'normal', // 'normal' in PROD,
	serverUrlSeq: 'https://dop-seq.svc.prd.kvk.nl/',
	cspRules: {
		defaultSrc: [`*.${baseUrl}`],
		connectSrc: [
			`*.${baseUrl}`,
			'https://zoeken.ondernemersplein.kvk.nl/api/v2/search/scorganisaties',
		],
		imgSrc: [`*.${baseUrl}`, `production.kvk-dop.bloomreach.cloud`],
		styleSrc: [`*.${baseUrl}`],
		frameSrc: [`*.${baseUrl}`],
		scriptSrc: [`*.${baseUrl}`],
	},
	speakablePages: [
		'tcm:114-436145-16',
		'tcm:114-436040-16',
		'tcm:114-453487-16',
		'tcm:114-475404-16',
		'tcm:114-454919-16',
		'tcm:114-475430-16',
		'tcm:114-436013-16',
		'tcm:114-463889-16',
		'tcm:114-464715-16',
		'tcm:114-471267-16',
	],
	faqStructedDataPages: [
		'tcm:114-436013-16',
		'tcm:114-436145-16',
		'tcm:114-463889-16',
		'tcm:114-464715-16',
		'tcm:114-475430-16',
		'tcm:114-475404-16',
		'tcm:114-436040-16',
		'tcm:114-471267-16',
		'tcm:114-454919-16',
		'tcm:114-453487-16',
	],
};

module.exports = environmentConfig;
