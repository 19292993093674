import { MarkupError } from '@/sections/markup/helpers/MarkupError';
// eslint-disable-next-line no-restricted-imports
import Link, { LinkProps } from 'next/link';
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from 'react';

export const DOPLink = forwardRef(
	(
		props: LinkProps &
			ComponentPropsWithoutRef<'a'> & { 'data-link-type': string },
		ref: ForwardedRef<HTMLAnchorElement>
	) => {
		try {
			// Don't render link if the URL is invalid
			// Base URL is not relevant when checking for validity
			// It has to be provided thought,
			// otherwise the URL constructor will throw for local links
			// eslint-disable-next-line no-new
			new URL(props.href, 'https://ondernemersplein.kvk.nl');
		} catch (error) {
			return (
				<>
					<MarkupError>{`Invalid URL: ${props.href}`}</MarkupError>
					{props.children}
				</>
			);
		}
		return <Link ref={ref} {...props} />;
	}
);
